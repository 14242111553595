import React from "react";
import { rehydrate } from "fela-dom";
import { ThemeProvider, StyleProvider, styleRenderer } from "ollui";
import theme from "./src/theme";
import "./global.css";

export const wrapRootElement = ({ element }) => {
  const renderer = styleRenderer();
  rehydrate(renderer);

  return (
    <StyleProvider renderer={renderer}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </StyleProvider>
  );
};
